import React, { useState, useEffect } from 'react';
import "./GeoMap.css";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import countries from '../../pages/v2/jsons/countries.json';

const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';
const getCountryDescription = (code) => {
  const country = countries.find((country) => country.code === code);
  return country ? country.description.en : "Unknown Country";
};

const Pin = ({ size = 20, fill = "#003366" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C7.5 0 4.103 3.487 4.103 7.777C4.103 12.625 12 24 12 24C12 24 19.897 12.625 19.897 7.777C19.897 3.487 16.5 0 12 0ZM12 11.609C10.664 11.609 9.58 10.523 9.58 9.222C9.58 7.92 10.664 6.834 12 6.834C13.336 6.834 14.42 7.92 14.42 9.222C14.42 10.523 13.336 11.609 12 11.609Z"
      fill={fill}
    />
  </svg>
);

const GeoMap = ({
  journeyActivities,
  journeyTotal_distance,
  journeyTotal_countries,
  isExpanded,
  onToggleMap,
  touchAreaRef
}) => {
  const [mapConfig, setMapConfig] = useState({
    width: 450,
    height: 600,
    translateExtent: [
      [-450 / 2, -600 / 2],
      [450 * 1.5, 600 * 1.5],
    ],
  });

  const getCountryCoords = (code) => {
    const country = countries.find((country) => country.code === code);
    return country ? country.coords : null;
  };

  const [journeyCountries, setJourneyCountries] = useState([]);

  useEffect(() => {
    // Ensure the map is collapsed when the component mounts
    if (isExpanded) {
      onToggleMap();
    }
  }, []); // Empty dependency array ensures this only runs on mount

  useEffect(() => {
    const uniqueCountries = new Set();

    journeyActivities.forEach((activity) => {
      activity.companies.forEach((company) => {
        uniqueCountries.add(company.country);
      });
    });

    const countriesWithCoords = Array.from(uniqueCountries)
      .map((code) => {
        const coords = getCountryCoords(code);
        return coords ? { code, ...coords } : null;
      })
      .filter(Boolean);

    setJourneyCountries(countriesWithCoords);
  }, [journeyActivities]);

  useEffect(() => {
    const updateDimensions = () => {
      const screenHeight = window.width;

      let width = 450;
      let height = isExpanded ? screenHeight : 450 / 2.9;

      if (screenHeight < 740) {
        width = 450;
        height = isExpanded ? screenHeight : 450 / 2.45;
      }

      setMapConfig({
        width: width,
        height: height,
        translateExtent: [
          [-600, -500],
          [1220, 1000],
        ],
      });
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [isExpanded]);

  const initialCenter = [8.2245, isExpanded ? 9.3 : 39.3999];

  return (
    <div
      ref={touchAreaRef}
      className={`div-map-container ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <div
        onClick={onToggleMap}
        className={`overlay ${isExpanded ? "expanded" : "collapsed"}`}
      >
        <div className="overlay-group-container">
          <div className="overlay-group">
            <div className="overlay-group-value">{journeyTotal_countries}</div>
            <div className="overlay-group-text">Countries</div>
          </div>
          <div className="vertical-line-map"></div>
          <div className="overlay-group">
            <div className="overlay-group-value">
              {journeyActivities.length}
            </div>
            <div className="overlay-group-text">Activities</div>
          </div>
          <div className="vertical-line-map"></div>
          <div className="overlay-group">
            <div className="overlay-group-value distance">
              {journeyTotal_distance?.value ?? "-"}
              <div className="overlay-group-unit">
                {journeyTotal_distance?.units ?? ""}
              </div>
            </div>
            <div className="overlay-group-text">Distance</div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <img
          onClick={onToggleMap}
          src="./images/icon_colapse_map.svg"
          alt={isExpanded ? "Collapse map" : "Expand map"}
          className="icon-collapse-expand"
        />
      )}
      {!isExpanded && (
        <div onClick={onToggleMap} className="click-to-expand-container">
          <img
            src="./images/icon_expand_map_new.svg"
            alt={isExpanded ? "Collapse map" : "Expand map"}
            className="icon-collapse-expand-new"
          />
          <div className="click-to-expand-text">Click to expand</div>
        </div>
      )}
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 320 }}
        width={mapConfig.width}
        height={mapConfig.height}
      >
        <ZoomableGroup
          center={initialCenter}
          minZoom={0.6}
          maxZoom={4}
          translateExtent={isExpanded ? mapConfig.translateExtent : undefined}
          disablePanning={!isExpanded}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <MemoizedGeographies geographies={geographies} />
            )}
          </Geographies>
          {journeyCountries.map((country, index) => (
            <MemoizedMarker key={index} country={country} />
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

const MemoizedGeographies = React.memo(({ geographies }) =>
  geographies.map((geo) => (
    <Geography
      key={geo.rsmKey}
      geography={geo}
      style={{
        default: {
          fill: "#007B8A66",
          stroke: "#FFFFFF",
          strokeWidth: 1,
          outline: "none",
        },
        hover: {
          fill: "#007B8A66",
          stroke: "#FFFFFF",
          strokeWidth: 1,
          outline: "none",
        },
        pressed: {
          fill: "#007B8A66",
          stroke: "#FFFFFF",
          strokeWidth: 1,
          outline: "none",
        },
      }}
    />
  ))
);

const MemoizedMarker = React.memo(({ country }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setIsHovered(prev => !prev);
  };

  return (
    <Marker
      key={country.code}
      coordinates={[country.longitude, country.latitude]}
      onClick={handleClick}
    >
      <g transform="translate(-10, -19)">
        <Pin />
        <circle
          cx={10}
          cy={7}
          r={3}
          fill="transparent"
        />
      </g>
      {isHovered && (
        <text
          textAnchor="middle"
          y={-22}
          style={{ fontFamily: "Roboto", fill: "#666666", fontSize: 11 }}
        >
          {getCountryDescription(country.code)}
        </text>
      )}
    </Marker>
  );
});



export default GeoMap;
