import React, { useEffect, useRef, useState } from 'react';
import "./SimpleGraph.css";
import Chart from "chart.js/auto";

const SimpleGraph = ({ atlist, valueObject }) => {
  const chartRef = useRef(null);
  const hasAnimatedOnce = useRef(false);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const canvas = chartRef.current;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if (screenWidth < 769 && screenHeight <= 650) {
        canvas.height = 60;
      } else if (screenWidth >= 769 && screenHeight <= 580) {
        canvas.height = 60;
      } else if (screenWidth <= 600) {
        canvas.height = 70;
        canvas.width = 90;
      } else if (screenWidth <= 850) {
        canvas.height = 65;
      } else if (screenWidth <= 1024) {
        canvas.height = 70;
      } else if (screenWidth <= 1350) {
        canvas.height = 75;
      } else {
        canvas.height = 90;
        canvas.width = 120;
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getMiddleValueSpace = (baseSize) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth <= 375 && screenHeight <= 690) {
      return 1.70;
    } else if (screenWidth <= 600) {
      return 1.75;
    }
  };

  const getMiddleValuePosition = (baseSize) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth <= 375 && screenHeight <= 690) {
      return 6;
    } else  {
      return 4.5;
    }
  };

  const getFontSize = (baseSize) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    if (screenWidth < 769 && screenHeight <= 650) {
      return `${baseSize * 0.95}px`;
    } else if (screenWidth >= 769 && screenHeight <= 580) {
      return `${baseSize * 1}px`;
    } else if (screenWidth <= 375 && screenHeight <= 690) {
      return `${baseSize * 1.15}px`;
    } else if (screenWidth <= 600) {
      return `${baseSize * 1.25}px`;
    } else if (screenWidth <= 1024) {
      return `${baseSize * 1.25}px`;
    } else {
      return `${baseSize * 1.3}px`;
    }
  };

  useEffect(() => {
    if (!atlist || atlist.length === 0) {
      const emptyDonutData = {
        datasets: [
          {
            data: [1],
            borderWidth: 0,
            backgroundColor: "#D1D1D1",
            weight: 0.05,
          },
        ],
      };

      const emptyDonutConfig = {
        type: "doughnut",
        data: emptyDonutData,
        options: {
          layout: { padding: 10 },
          cutout: "60%",
          plugins: { tooltip: { enabled: false }, legend: { display: false } },
          hover: { mode: null },
          animation: false,
        },
      };

      const emptyDonutChart = new Chart(chartRef.current, emptyDonutConfig);
      return () => emptyDonutChart.destroy();
    }

    // Filter out items with a value of 0
    const filteredData = atlist.filter(item => item.value > 0);

    // Sort data and calculate the total value
    const sortedData = filteredData.sort((a, b) => b.value - a.value);
    const totalValue = sortedData.reduce((sum, item) => sum + item.value, 0);
    const minThreshold = totalValue * 0.032;

    // Adjust values to ensure no slice is below the minimum threshold
    const adjustedData = sortedData.map((item) => ({
      ...item,
      adjustedValue: Math.max(item.value, minThreshold),
    }));

    // Normalize adjusted values to maintain the total value
    const adjustedTotal = adjustedData.reduce((sum, item) => sum + item.adjustedValue, 0);
    const normalizedData = adjustedData.map((item) => ({
      ...item,
      normalizedValue: (item.adjustedValue / adjustedTotal) * totalValue,
    }));

    const colors = ['#0099CCB2', '#0097AE', '#004C71', '#0B3C6C'];
    const colorMap = {};

    normalizedData.forEach((item, index) => {
      const colorIndex = index % colors.length;
      colorMap[item.production_activity_code] = colors[colorIndex];
    });

    const data = {
      labels: normalizedData.map((item) => item.production_activity_code),
      datasets: [
        {
          data: normalizedData.map((item) => item.normalizedValue),
          backgroundColor: normalizedData.map((item) => colorMap[item.production_activity_code]),
          borderWidth: 1,
          borderColor: "white",
          hoverOffset: 0,
        },
      ],
    };

    const doughnutLabelsLine = {
      id: "doughnutLabelsLine",
      afterDraw(chart) {
        const { ctx } = chart;

        // Draw the value in the center
        ctx.save();
        ctx.font = `bold ${getFontSize(9)} Roboto`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#1C698B";
        ctx.fillText(
          `${valueObject.value} ${valueObject.units}`,
          chart.chartArea.width / 2,
          chart.chartArea.height / 2 + getMiddleValuePosition(10)
        );
        ctx.restore();
      },
      beforeDraw(chart) {
        const { ctx, chartArea } = chart;

        // Draw background circle
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        ctx.shadowBlur = 8;
        ctx.beginPath();
        ctx.arc(chartArea.width / 2, chartArea.height / getMiddleValueSpace(1.55), chartArea.height * 0.55, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        layout: { padding: { left: 0, right: 0, top: 5, bottom: 5 } },
        rotation: -90,
        plugins: { tooltip: { enabled: false }, legend: { display: false } },
        interaction: { mode: "index" },
        cutout: "80%",
        responsive: true,
        maintainAspectRatio: false,
        animation: hasAnimatedOnce.current
          ? false
          : {
              duration: 1000,
              onComplete: () => {
                hasAnimatedOnce.current = true;
              },
            },
      },
      plugins: [doughnutLabelsLine],
    };

    const myChart = new Chart(chartRef.current, config);

    setImagesLoaded(true);

    return () => myChart.destroy();
  }, [atlist, valueObject]);

  return (
    <div className="composition-container-simple">
      <div className="composition-line-simple">
        <div className="container-of-donut-container-simple">
          <canvas ref={chartRef} width="90" height="70"></canvas>
        </div>
      </div>
    </div>
  );
};

export default SimpleGraph;
