import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import './ZaraTabs.css';

const ZaraTabs = ({
  refNextBack,
  data,
  currentVisibleSlide,
  setCurrentVisibleSlide,
}) => {
  const [activeTab, setActiveTab] = useState(data[0].id);
  const tabRefs = useRef([]);
  const tabsContainerRef = useRef(null);

  const [isDown, setIsDown] = useState(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const isDragging = useRef(false); // Track if dragging occurred

  const handleTabClick = (id, index) => {
    if (isDragging.current) return; // Prevent click during dragging

    setActiveTab(id);
    setCurrentVisibleSlide(id);

    if (tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  const handleMouseDown = (e) => {
    setIsDown(true);
    isDragging.current = false; // Reset dragging flag
    tabsContainerRef.current.classList.add('active');
    startX.current = e.pageX - tabsContainerRef.current.offsetLeft;
    scrollLeft.current = tabsContainerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    tabsContainerRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDown(false);
    tabsContainerRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;

    e.preventDefault();
    isDragging.current = true;
    const x = e.pageX - tabsContainerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1;
    tabsContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  useImperativeHandle(
    refNextBack,
    () => ({
      goToNextSlide() {
        const currentIndex = data.findIndex((item) => item.id === activeTab);
        if (currentIndex < data.length - 1) {
          handleTabClick(data[currentIndex + 1].id, currentIndex + 1);
        }
      },
      goToPrevSlide() {
        const currentIndex = data.findIndex((item) => item.id === activeTab);
        if (currentIndex > 0) {
          handleTabClick(data[currentIndex - 1].id, currentIndex - 1);
        }
      },
    }),
    [activeTab, data]
  );

  useEffect(() => {
    const container = tabsContainerRef.current;

    container.addEventListener('mousedown', handleMouseDown);
    container.addEventListener('mouseleave', handleMouseLeave);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      container.removeEventListener('mouseleave', handleMouseLeave);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDown]);

  useEffect(() => {
    const container = document.querySelector('.zara-tabs-container');
    if (container) {
      container.addEventListener('touchmove', (e) => e.stopPropagation(), { passive: false });
    }
  }, []);

  return (
    <div className="zara-tabs-container" ref={tabsContainerRef}>
      <div className="zara-tabs">
        {data.map(({ text, id }, index) => (
          <div
            key={id}
            ref={(el) => (tabRefs.current[index] = el)}
            className={`zara-tab ${activeTab === id ? 'active' : ''}`}
            onClick={() => handleTabClick(id, index)}
          >
            <div>{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZaraTabs;
