import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { useLocation } from "react-router-dom";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import _, { debounce, throttle } from "lodash";
import "./Product.css";
import ProductHeaderV2 from "../../components/v2/ProductHeaderV2";
import ProductGallery from "../../components/v2/product/ProductGallery";
import ScoreInfo from "../../components/v2/ScoreInfo";
import ScoreVertical from "../../components/v2/ScoreVertical";
import IndicatorInfo from "../../components/v2/IndicatorInfo";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import GeoMap from "../../components/v2/GeoMap";
import Journey from "../../components/v2/Journey";
import AditionalInfo from "../../components/v2/Aditionalinfo.jsx";
import Properties from "../../components/v2/Properties";
import BillOfMaterials from "../../components/v2/BillOfMaterials";
import CareInstructions from "../../components/v2/CareInstructions";
import MaintenanceDiscard from "../../components/v2/MaintenanceDiscard";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";
import FooterDesktop from "../../components/desktop/FooterDesktop";
import CompositionGraph from "../../components/v2/CompositionGraph.jsx";
import CompositionGraphDesktop from "../../components/desktop/CompositionGraphDesktop.jsx";
import CertificationsNew from "../../components/desktop/CertificationsNew";
import CertificationsDesktop from "../../components/desktop/CertificationsDesktop";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Fab } from "@mui/material";
//imports dos jsons para mapear tradução
import countries from "./jsons/countries.json";
import colors from "./jsons/colors.json";
import fashions from "./jsons/fashion.json";
import collections from "./jsons/collection.json";
import fits from "./jsons/fit.json";
import seasons from "./jsons/seasons.json";
import product_type from "./jsons/type.json";
import compositions from "../../pages/v2/jsons/compositions.json";
import SimilarProducts from "../../components/v2/SimilarProducts";
//Icones corrigidos
import BillOfMaterialsIcon from "../../img/BillOfMaterials.svg";
import CareInstructionsIcon from "../../img/CareInstructions.svg";
import PropertiesIcon from "../../img/Properties.svg";
import RepairDiscardIcon from "../../img/RepairDiscard.svg";
import DppLogoDesktopProduct from "../../img/dpplogoproductdesktop.svg";
import arrow_left from "../../img/arrow_left_new.svg";
import dpplogooverlay from "../../img/dpp-logo.png";
import CompositionGraphNew from "../../components/v2/CompositionGraphNew.jsx";
import CompositionBarChart from "../../components/v2/CompositionBarChart.jsx";
import ZaraTabs from "../../components/v2/ZaraTabs.jsx";

//pagina para mostrar o produto e todas as informações do mesmo
//será esta a pagina que recebe todos os componentes
//contem função para verificar a existencia do produto a apresentar
const Product = ({ productList, productListEvent, hasTokenEvent }) => {
  const [queryParameters] = useSearchParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const touchAreaRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const params = new URLSearchParams(location.search);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const [isSimilarProductsOpen, setSimilarProductsOpen] = useState(false);
  const [isPageChangeAllowed, setIsPageChangeAllowed] = useState(true);

  const [showOverlay, setShowOverlay] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const [isOverlayClickable, setIsOverlayClickable] = useState(false);

  // Check if overlay has already been shown during this session
  useEffect(() => {
    const hasSeenOverlay = sessionStorage.getItem("hasSeenOverlay");
    if (!hasSeenOverlay) {
      const handlePageChange = () => {
        if (currentPage === 1) {
          setIsDismissed(true);
          sessionStorage.setItem("hasSeenOverlay", "true");
        }
      };

      const overlayTimer = setTimeout(() => {
        if (!isDismissed) {
          setShowOverlay(true);
          setTimeout(() => setIsOverlayClickable(true), 2000); // 2-second delay to enable clicks
        }
      }, 3000);

      handlePageChange();

      return () => {
        clearTimeout(overlayTimer);
      };
    }
  }, [currentPage, isDismissed]);


  const [showOverlayDesktop, setShowOverlayDesktop] = useState(false);
  const [isOverlayClickableDesktop, setIsOverlayClickableDesktop] =
    useState(false);
  const [isDismissedDesktop, setIsDismissedDesktop] = useState(false);

  useEffect(() => {
    // Check if overlay has already been shown during this session
    const hasSeenOverlay = sessionStorage.getItem("hasSeenOverlay");

    if (!hasSeenOverlay) {
      // Define a function to handle page changes and mark overlay as shown if on the target page
      const handlePageChange = () => {
        if (currentPage === 1) {
          setIsDismissedDesktop(true);
          sessionStorage.setItem("hasSeenOverlay", "true"); // Mark overlay as shown in session storage
        }
      };

      // Set a timer to show the overlay if not dismissed after 3 seconds
      const overlayTimer = setTimeout(() => {
        if (!isDismissedDesktop) {
          setShowOverlayDesktop(true);

          // Enable clicks on the overlay after 2 seconds
          setTimeout(() => setIsOverlayClickableDesktop(true), 1000);
        }
      }, 3000);

      // Call the handlePageChange function to mark as dismissed if already on target page
      handlePageChange();

      // Clean up timers on component unmount or when dependencies change
      return () => {
        clearTimeout(overlayTimer);
      };
    }
  }, [currentPage, isDismissedDesktop]);

  useEffect(() => {
    // Function to hide overlay on click if overlay is clickable
    const handleClick = () => {
      if (isOverlayClickableDesktop) {
        setShowOverlayDesktop(false);
        setIsOverlayClickableDesktop(false); // Reset click enabled state
        sessionStorage.setItem("hasSeenOverlay", "true"); // Mark overlay as shown in session storage
      }
    };

    // Add event listener when overlay is shown
    if (showOverlayDesktop) {
      document.addEventListener("click", handleClick);
    }

    // Cleanup event listener
    return () => document.removeEventListener("click", handleClick);
  }, [showOverlayDesktop, isOverlayClickableDesktop]);

  const handleGoToPage = () => {
    setShowOverlayDesktop(false); // Hide the overlay if clicked
    goToPage(1);
  };



  // Function to handle overlay dismissal after it appears
  const handleDismissOverlay = () => {
    setShowOverlay(false);
    setIsOverlayClickable(false);
    sessionStorage.setItem("hasSeenOverlay", "true");
  };

  //num max de paginas
  const maxPages = 5;

  //-----------------------------

  const goToPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageUp = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage - 1;
      return newPage >= 0 ? newPage : 0;
    });
  };
  const handlePageDown = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      return newPage < maxPages ? newPage : maxPages - 1;
    });
  };

  // CASO EXISTA TOKEN
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("expositionMude")) {
      const expositionMude = params.get("expositionMude");
      if (expositionMude === "true") {
        const storedToken = JSON.parse(localStorage.getItem("authToken"));
        const newToken = generateToken();
        hasTokenEvent(true, expirationDate);
        params.delete("expositionMude");
        navigate(`${location.pathname}?${params.toString()}`, {
          replace: true,
        });
      }
    } else {
      /*    localStorage.removeItem("authToken"); */
      /*   console.log("Parâmetro expositionMude não encontrado."); */
    }
  }, []);

  // <-----------------------GERAR TOKEN CASO PRODUTO SEJA DA LISTA------------------------------------------>
  const startDate = new Date();
  const expirationDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    23,
    59,
    59,
    999
  );

  const generateToken = () => {
    const token = {
      value: "your-unique-token",
      startDate: startDate.getTime(),
      expiration: expirationDate.getTime(),
    };

    localStorage.setItem("authToken", JSON.stringify(token));
    return token;
  };

  const isTokenValid = (token) => {
    const currentTime = new Date().getTime();
    return token && token.expiration > currentTime; // Verifica se o token existe e ainda não expirou
  };
  // <-------------------------------------------token----------------->

  // scroll a paginas do conteudo do lado direito
  const containerRef = useRef(null);

  useEffect(() => {
    const preventPullToRefresh = (e) => {
      if (e.touches.length > 1) {
        // If there are more than one touches, it's a multi-touch gesture, so we ignore it.
        return;
      }
      if (e.touches[0].clientY > 0) {
        // Prevent the pull-to-refresh action
        e.preventDefault();
      }
    };

    // Add the event listener
    document.addEventListener("touchmove", preventPullToRefresh, {
      passive: false,
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("touchmove", preventPullToRefresh);
    };
  }, []);

// Função para manipular os eventos scroll/gesture (page change)
const debouncedHandleWheel = _.debounce((event) => {
  const rightContainer = containerRef.current;

  // Verifica se a página está válida para alterar ou se o mapa está expandido
  if (!rightContainer || !isPageChangeAllowed || /* isSimilarProductsOpen || */ isMapExpanded)
    return;

  // Deteção se movimento está a mudar conteúdo para baixo
  const isScrollingDown = event.deltaY > 0;
  const currentScrollTop = rightContainer.scrollTop;

  // Ajuste deltaY para rolagem suave e verificar se é gesto de touchpad
  const adjustedDeltaY =
    event.deltaMode === 0 ? event.deltaY / 50 : event.deltaY;

  // Bloqueia eventos de touchpad (deltaMode === 0 e deltaY pequeno)
  if (event.deltaMode === 0 && Math.abs(event.deltaY) < 50) {
    return;
  }

  // Se não for gesto de touchpad, continue com o scroll
  if (rightContainer.scrollHeight > rightContainer.clientHeight) {
    if (
      isScrollingDown &&
      currentScrollTop + rightContainer.clientHeight >=
        rightContainer.scrollHeight - 1
    ) {
      setCurrentPage((prevPage) => {
        if (prevPage < 3) {
          return prevPage + 1;
        } else {
          /* setSimilarProductsOpen(true); */ // Abre similar products se for a página 3
          return prevPage;
        }
      });
      event.preventDefault();
    } else if (!isScrollingDown && currentScrollTop === 0) {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      event.preventDefault();
    }
  } else {
    if (isScrollingDown) {
      setCurrentPage((prevPage) => {
        if (prevPage < 3) {
          return prevPage + 1;
        } else {
          /* setSimilarProductsOpen(true); */
          return prevPage;
        }
      });
      event.preventDefault();
    } else {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      event.preventDefault();
    }
  }

  // Desabilita ações por 200ms - necessário por causa dos touch gestures serem contínuos
  setIsPageChangeAllowed(false);
  setTimeout(() => {
    setIsPageChangeAllowed(true);
  }, 200);
}, 100);

  // Função para manipular eventos do teclado (setas para cima/baixo)
  const handleKeyDown = (event) => {
    const rightContainer = containerRef.current;

    // Se a página de produtos similares estiver aberta
    /* if (isSimilarProductsOpen) {
      if (event.key === "ArrowUp") {
        // Fecha o similar products, mas não altera a página
        setSimilarProductsOpen(false);
        event.preventDefault();
      }
      // Não faz nada se a similarProducts estiver aberta e pressionar para baixo
      return;
    } */

    // Verifica se a página está válida para alterar
    if (!rightContainer || !isPageChangeAllowed) return;

    // Verifica se a tecla pressionada é a seta para cima ou para baixo
    if (event.key === "ArrowDown") {
      setCurrentPage((prevPage) => {
        if (prevPage < 3) {
          return prevPage + 1;
        } else {
          setSimilarProductsOpen(true); // Abre similar products se for a página 3
          return prevPage;
        }
      });
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      event.preventDefault();
    }

    // Desabilita ações por 200ms para evitar múltiplos triggers
    setIsPageChangeAllowed(false);
    setTimeout(() => {
      setIsPageChangeAllowed(true);
    }, 100);
  };

  const handleArrowClick = (direction) => {
    const rightContainer = containerRef.current;

    // Se a página de produtos similares estiver aberta
    /* if (isSimilarProductsOpen) {
      if (direction === "ArrowUp") {
        // Fecha o similar products, mas não altera a página
        setSimilarProductsOpen(false);
      }
      // Não faz nada se a similarProducts estiver aberta e pressionar para baixo
      return;
    } */

    // Verifica se a página está válida para alterar
    if (!rightContainer || !isPageChangeAllowed) return;

    // Verifica se a tecla pressionada é a seta para cima ou para baixo
    if (direction === "down") {
      setCurrentPage((prevPage) => {
        if (prevPage < 3) {
          return prevPage + 1;
        } else {
          setSimilarProductsOpen(true); // Abre similar products se for a página 3
          return prevPage;
        }
      });
    } else if (direction === "up") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    }

    setIsPageChangeAllowed(false);
    setTimeout(() => {
      setIsPageChangeAllowed(true);
    }, 100);
  };

  useEffect(() => {
    const rightContainer = containerRef.current;

    if (rightContainer) {
      // Add event listeners for scroll/swipe and clicks
      rightContainer.addEventListener("wheel", debouncedHandleWheel);
    }

    return () => {
      if (rightContainer) {
        rightContainer.removeEventListener("wheel", debouncedHandleWheel);
      }
    };
  }, [debouncedHandleWheel, /* isSimilarProductsOpen */]);

  // Adiciona o listener de eventos de teclado ao carregar o componente
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPageChangeAllowed, /* isSimilarProductsOpen */]);

  useEffect(() => {
    // Verifica se o objeto matchMedia está disponível
    if (typeof window === "undefined") {
      return;
    }
    const mediaQueryList = window.matchMedia("(max-width: 768px)");
    // Função para atualizar o estado com base na mudança da condição de mídia
    const handleChange = (e) => {
      setIsMobile(e.matches);
    };
    // Adiciona o ouvinte para mudanças
    mediaQueryList.addEventListener("change", handleChange);
    // Verifica a condição inicial
    handleChange(mediaQueryList);
    // Função de limpeza para remover o ouvinte
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, []);

  const handleBackClick = () => {
    navigate("/view");
  };
  const handleMainpage = () => {
    setCurrentPage(0);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleOpenMenu = () => {
    setMenuOpen(true);
  };
  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  const carouselRef = useRef();
  const handleNextClick = () => {
    carouselRef.current.goToNextSlide();
  };
  const handlePrevClick = () => {
    carouselRef.current.goToPrevSlide();
  };
  
  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const handlersTab = useSwipeable({
    onSwipedLeft: () => setActiveTab((prev) => (prev < 2 ? prev + 1 : prev)),
    onSwipedRight: () => setActiveTab((prev) => (prev > 0 ? prev - 1 : prev)),
    preventDefaultTouchmoveEvent: true,
  });
  //PAGINA 5
  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(0);
  //-----------------VERIFICAÇÕES--------------------
  const getCountryDescription = (code) => {
    const country = countries.find((country) => country.code === code);
    return country ? country.description.en : "Unknown Country";
  };
  const getColorDescription = (code) => {
    const color = colors.find((color) => color.code === code);
    return color ? color.description.en : "-";
  };
  const getFashionDescription = (code) => {
    const fashion = fashions.find((fashion) => fashion.code === code);
    return fashion ? fashion.description.en : "-";
  };
  const getFitsDescription = (code) => {
    const fit = fits.find((fit) => fit.code === code);
    return fit ? fit.description.en : "-";
  };
  const getProductTypeDescription = (code) => {
    for (const type of product_type) {
      const product_type_item = type.options.find(
        (product_type_item) => product_type_item.code === code
      );
      if (product_type_item) {
        return product_type_item.description.en;
      }
    }
    return "-";
  };
  const getSeasonsDescription = (code) => {
    const season = seasons.find((season) => season.code === code);
    return season ? season.description.en : "-";
  };
  const getCollectionDescription = (code) => {
    const collection = collections.find(
      (collection) => collection.code === code
    );
    return collection ? collection.description.en : "-";
  };
  //-------------FIM DAS VERIFICAÇÕES----------------

  const handleToggleMap = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  const [startY, setStartY] = useState(0);
  const swipeThreshold = 70; // Adjust this threshold as needed

  useEffect(() => {
    // Simulate loading time, replace with your actual loading logic
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 0);
    return () => clearTimeout(timeout); // Cleanup on component unmount
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Eventualmente adicionar para uma pagina de Loading...
  }
  // Procura o produto na lista pelo seu ID
  const product = productList.find(
    (p) => p.id === queryParameters.get("productId")
  );

  /*   const params = new URLSearchParams(location.search); */

  /*   const storedToken = JSON.parse(localStorage.getItem("authToken")); */

  /*   productListEvent.forEach((item) => {
    if (item.id === product.id) {
      if (!storedToken) {
        console.log("Token não encontrado. Gerando um novo...");
        const newToken = generateToken();
        hasTokenEvent(true, expirationDate);
      }
    }
  }); */

  // >----------------------- FIM GERAR TOKEN CASO PRODUTO SEJA DA LISTA------------------------------------------<

  // Se o produto não existir, dá return à pagina NotFound
  if (!product) {
    return <Navigate to="/error" replace />;
  }
  const returnToMainPage = () => {
    setCurrentPage(0); // define a pagina para a primeira (main product)
  };

  const handleTouchStart = (event) => {
    if (!isMobile) return; // Only run if isMobile is true
    if (!isMenuOpen) {
      setStartY(event.touches[0].clientY);
    } else {
      // Ignore swipe gestures when the menu is open
      event.preventDefault();
    }
  };

  const handleTouchMove = (event) => {
    if (!isMobile || !startY) return; // Only run if isMobile is true and startY is set

    const deltaY = event.touches[0].clientY - startY;
    const scrollArea = scrollAreaRef.current;
    const touchArea = touchAreaRef.current;

    // Prevent swipes within touchArea
    if (touchArea && touchArea.contains(event.target)) {
      return;
    }

    // Check if the user is scrolling inside scrollAreaRef
    if (scrollArea && scrollArea.contains(event.target)) {
      const isAtTop = scrollArea.scrollTop === 0;
      const isAtBottom =
        scrollArea.scrollTop + scrollArea.clientHeight ===
        scrollArea.scrollHeight;

      // Allow scrolling inside scrollArea
      if (!(isAtTop && deltaY > 0) && !(isAtBottom && deltaY < 0)) {
        // User is scrolling inside the scroll area, do not trigger page swipe
        return;
      }
    }

    // Handle swipe actions when outside scrollArea or at top/bottom of scrollArea
    if (Math.abs(deltaY) > swipeThreshold) {
      if (deltaY < 0) {
        handlePageDown();
      } else {
        handlePageUp();
      }
      setStartY(0);
    }
  };

  const handleTouchMoveDebounced = debounce(handleTouchMove, 10);

  const handleTouchEnd = () => {
    if (!isMobile) return; // Only run if isMobile is true
    setStartY(0); // Reset startY on touch end
  };

  const getPageTitle = (currentPage) => {
    switch (currentPage) {
      case 1:
        return "Index";
      case 2:
        return "Detailed Index";
      case 3:
        return "The Journey";
      case 4:
        return "Additional Information";
      default:
        return "";
    }
  };
  const pageTitle = getPageTitle(currentPage);
  let pageContent;
  const dataDetailedIndex = [
    {
      id: 0,
      title: "Water Consumption",
      description: {
        indicator: "Water consumption ratio per production activities.",
        indicator_desktop:
          product.dpp.product_info.detailed_index.water_consumption.value +
          " litres of water were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "Comparative scale with the Portuguese T&C industry's historical values.",
        main: "Total litres of water used in this article's production.",
        complexgraph_desktop:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main_desktop:
          "This value represents the total litres of water used in the production of this article.",
      },
      icon: "./images/icon_water_v2.svg",
      value: product.dpp.product_info.detailed_index.water_consumption.value,
      units: product.dpp.product_info.detailed_index.water_consumption.units,
      scale: product.dpp.product_info.detailed_index.water_consumption.scale,
      production_activities:
        product.dpp.product_info.detailed_index.water_consumption
          .production_activities,
    },
    {
      id: 1,
      title: "Carbon Footprint",
      description: {
        indicator: "Carbon footprint ratio per production activities.",
        indicator_desktop:
          product.dpp.product_info.detailed_index.carbon_footprint.value +
          " kg of CO2 were produced to produce this article, distributed among the following production activities.",
        complexgraph:
          "Comparative scale with the Portuguese T&C industry's historical values.",
        main: "Total carbon footprint produced in this article's production.",
        complexgraph_desktop:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main_desktop:
          "This value represents the total carbon footprint produced in the production of this article.",
      },
      icon: "./images/icon_co2_v2.svg",
      value: product.dpp.product_info.detailed_index.carbon_footprint.value,
      units: product.dpp.product_info.detailed_index.carbon_footprint.units,
      scale: product.dpp.product_info.detailed_index.carbon_footprint.scale,
      production_activities:
        product.dpp.product_info.detailed_index.carbon_footprint
          .production_activities,
    },
    {
      id: 2,
      title: "Chemical Consumption",
      description: {
        indicator: "Chemical consumption ratio per production activities.",
        indicator_desktop:
          product.dpp.product_info.detailed_index.chemical_products.value +
          " grams of chemicals were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "Comparative scale with the Portuguese T&C industry's historical values.",
        main: "Total chemicals used in this article's production.",
        complexgraph_desktop:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main_desktop:
          "This value represents the total chemicals used in the production of this article.",
      },
      icon: "./images/icon_chemical_v2.svg",
      value: product.dpp.product_info.detailed_index.chemical_products.value,
      units: product.dpp.product_info.detailed_index.chemical_products.units,
      scale: product.dpp.product_info.detailed_index.chemical_products.scale,
      production_activities:
        product.dpp.product_info.detailed_index.chemical_products
          .production_activities,
    },
    {
      id: 3,
      title: "Recovered Waste",
      description: {
        indicator: "Recovered waste ratio per production activities.",
        indicator_desktop:
          product.dpp.product_info.detailed_index.recovered_waste.value +
          " % of recovered waste in the production of this article, distributed among the following production activities.",
        complexgraph:
          "Comparative scale with the Portuguese T&C industry's historical values.",
        main: "Total recovered waste in this article's production.",
        complexgraph_desktop:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main_desktop:
          "This value represents the total recovered waste in the production of this article.",
      },
      icon: "./images/icon_recovered_waste_v2.svg",
      value: product.dpp.product_info.detailed_index.recovered_waste.value,
      units: product.dpp.product_info.detailed_index.recovered_waste.units,
      scale: product.dpp.product_info.detailed_index.recovered_waste.scale,
      production_activities:
        product.dpp.product_info.detailed_index.recovered_waste
          .production_activities,
    },
  ];
  const data = [
    {
      image: PropertiesIcon,
      text: "Properties",
      id: 0,
    },
    {
      image: CareInstructionsIcon,
      text: "Care Instructions",
      id: 1,
    },
    {
      image: RepairDiscardIcon,
      text: "Repair and Discard",
      id: 2,
    },
    {
      image: BillOfMaterialsIcon,
      text: "Bill of Materials",
      id: 3,
    },
  ];
  const weight = product.dpp.product_info.properties.weight;
  const displayWeight = weight !== null ? weight + " kg" : "-";
  const categoriesData = [
    {
      name: "Type of Product",
      description: getProductTypeDescription(
        product.dpp.product_info.properties.type
      ),
    },
    {
      name: "Fit",
      description: getFitsDescription(product.dpp.product_info.properties.fit),
    },
    {
      name: "Category",
      description: getCollectionDescription(
        product.dpp.product_info.properties.collection
      ),
    },
    {
      name: "Fashion",
      description: getFashionDescription(
        product.dpp.product_info.properties.fashion
      ),
    },
    {
      name: "Color",
      description:
        product.dpp.product_info.properties.color &&
        product.dpp.product_info.properties.color.length > 0
          ? product.dpp.product_info.properties.color
              .map((color) => getColorDescription(color))
              .join(", ")
          : "-",
    },
    {
      name: "Colection",
      description:
        product.dpp.product_info.properties.season &&
        product.dpp.product_info.properties.season.length > 0
          ? product.dpp.product_info.properties.season
              .map((season) => getSeasonsDescription(season))
              .join(", ")
          : "-",
    },
    {
      name: "Weight",
      description: displayWeight,
    },
    {
      name: "Variants",
      description:
        product.dpp.product_info.properties.number_of_variants || "-",
    },
    {
      name: "Reciclability",
      description:
        product.dpp.product_info.properties.reciclability !== null
          ? product.dpp.product_info.properties.reciclability
            ? "Yes"
            : "No"
          : "-",
    },
    {
      name: "Microplastics",
      description:
        product.dpp.product_info.properties.microplastics !== null
          ? product.dpp.product_info.properties.microplastics
            ? "Yes"
            : "No"
          : "-",
    },
    {
      name: "Dangerous Chemicals",
      description:
        product.dpp.product_info.properties.dangerous_chemicals !== null
          ? product.dpp.product_info.properties.dangerous_chemicals
            ? "Yes"
            : "No"
          : "-",
    },
  ];
  const longtext_title = ""; 

  if (currentPage === 0 && isMobile) {
    pageContent = (
      <div className="page1-container-v2">
        <div className="header-content-v2">
          <img
            src="./images/icon_arrow_back.svg"
            alt="product-arrow-goback"
            className="product-arrow-goback"
            onClick={handleBackClick}
          />
          <div className="header-content-v2-content">
            <div className="header-content-v2-title">
              {product.dpp.product_info.name}
            </div>
            <div className="header-content-v2-text">
              {product.dpp.product_info.brand && (
                <>{product.dpp.product_info.brand} · </>
              )}
              Made In {getCountryDescription(product.dpp.product_info.made_in)}
            </div>
          </div>
          {/* <img
            src="./images/icon_interrogation.svg"
            alt="product-interrogation"
            className="product-interrogation"
          /> */}
        </div>
        <div className="product-maindiv-container-v2">
          <ProductGallery
            recycled={product.dpp.product_info.composition.recycled}
            compositionList={product.dpp.product_info.composition.fibers}
            certifications={product.dpp.product_info.certifications}
            productImgs={product.dpp.product_info.img}
            touchAreaRef={touchAreaRef}
            handleNavigation={returnToMainPage}
            product_info={product.dpp.product_info}
            score={product.dpp.product_info.score}
          />
        </div>
        <div className="tabs-container">
          <div className="tabs-header">
            <div
              className={`tab-button ${activeTab === 0 ? "active" : ""}`}
              onClick={() => setActiveTab(0)}
            >
              Composition
            </div>
            <div
              className={`tab-button ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Index
            </div>
            <div
              className={`tab-button ${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Certifications
            </div>
          </div>
          <div
            className="tabs-underline"
            style={{ left: `${activeTab * 30.706}%` }}
          ></div>

          <div className="tabs-content" {...handlersTab}>
            {activeTab === 0 && (
              <div className="composition-new">
                <div className="composition-new-circular">
                  <CompositionGraphNew
                    recycled={product.dpp.product_info.composition.recycled}
                  />
                </div>
                <div className="composition-new-bar">
                  <CompositionBarChart
                    compositions={product.dpp.product_info.composition.fibers}
                  />
                </div>
              </div>
            )}
            {activeTab === 1 && (
              <div className="product-maindiv-score-v2">
                <ScoreInfo
                  currentPage={0}
                  icon={"./images/icon_AF.svg"}
                  title={"Overall Index"}
                  description={""}
                  score={product.dpp.product_info.score}
                />
              </div>
            )}
            {activeTab === 2 && (
              <div className="certifications-container-desktop-new">
                <CertificationsNew
                  certifications={product.dpp.product_info.certifications}
                ></CertificationsNew>
              </div>
            )}
          </div>
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 0 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div">
            {/* {showOverlayDesktop && <div className="overlay-desktop"></div>} */}
            <div className="product-maindiv-score-v2-desktop">
              <div className="box-score-container-v2-desktop">
                <ScoreInfo
                  currentPage={0}
                  icon={"./images/icon_AF.svg"}
                  title={"Overall Index"}
                  description={""}
                  score={product.dpp.product_info.score}
                />
                {/* <div className="box-clicktosee" onClick={handleGoToPage}>
                  <div className="box-clicktosee-text">
                    {" "}
                    Click to explore our{" "}
                  </div>
                  <img
                    src={DppLogoDesktopProduct}
                    alt="icon-arrows"
                    className="box-clicktosee-img"
                  />
                  <img
                    src="./images/icon_arrow_back.svg"
                    alt="product-arrow-goback"
                    className="box-clicktosee-arrow"
                  />
                  {showOverlayDesktop && (
                    <img
                      src="./images/box-clicktosee-gif.gif"
                      alt="box-clicktosee-gif"
                      className="box-clicktosee-gif"
                    />
                  )}
                </div> */}
                <div className="title-product-maindiv-score-v2-desktop">
                  <div
                    className="click-to-see-dpp-product"
                    onClick={handleGoToPage}
                  >
                    {" "}
                    Click to explore our{" "}
                  </div>
                  <img
                    src={DppLogoDesktopProduct}
                    alt="icon-arrows"
                    className="img-product-maindiv-score-v2-desktop"
                  />
                </div>
              </div>
            </div>
            <div className="certifications-container-desktop">
              <CertificationsDesktop
                certifications={product.dpp.product_info.certifications}
              ></CertificationsDesktop>
            </div>
            {/* <div className="composition-desktop">
              <CompositionGraphDesktop
                compositionList={product.dpp.product_info.composition.fibers}
                recycled={product.dpp.product_info.composition.recycled}
              />
            </div> */}

            <div className="composition-new-container">
              {!isMobile && (
                <div className="certifications-desktop-title"> Composition</div>
              )}
              <div className="composition-new">
                <div className="composition-new-circular">
                  <CompositionGraphNew
                    recycled={product.dpp.product_info.composition.recycled}
                  />
                </div>
                <div className="composition-new-bar">
                  <CompositionBarChart
                    compositions={product.dpp.product_info.composition.fibers}
                  />
                </div>
              </div>
            </div>
            <div className="additional-info-container-desktop">
              <div className="additional-info-product-container">
                <ZaraTabs
                  refNextBack={carouselRef}
                  data={data}
                  setCurrentVisibleSlide={setCurrentVisibleSlide}
                />
              </div>
              <div className="product-maindiv-page1-v2-desktop" {...handlers}>
                <div className="container-arrows-content-desktop-product">
                  {currentVisibleSlide === 0 && (
                    <div className="properties">
                      {<Properties categories={categoriesData} />}
                    </div>
                  )}
                  {currentVisibleSlide === 1 && (
                    <div className="care-instructions-products">
                      {
                        <CareInstructions
                          care_instructions={
                            product.dpp.product_info.care_instructions
                          }
                        />
                      }
                    </div>
                  )}
                  {currentVisibleSlide === 2 && (
                    <div className="maintenance-discard">
                      {
                        <MaintenanceDiscard
                          longtext_text={
                            product.dpp.product_info
                              .maintenance_repair_instructions
                          }
                          longtext_title={longtext_title}
                        />
                      }
                    </div>
                  )}
                  {currentVisibleSlide === 3 && (
                    <div className="bill-of-materials">
                      {
                        <BillOfMaterials
                          data={product.dpp.product_info.bill_of_materials}
                        ></BillOfMaterials>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 1 && isMobile) {
    pageContent = (
      <div className="page2-container-v2">
        <div className="product-maindiv-page">
          <div className="div-score-sac-ses-v2">
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_AF.svg"}
              title={"Overall Index"}
              description={
                "The product's overall index considering the 4 dimensions of sustainability (environmental, circular, economical and social). A higher rating represents a more sustainable product."
              }
              score={product.dpp.product_info.score}
            />
            <div className="sac-ses-new">
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_sac_v2.svg"}
                title={"Environmental and Circularity"}
                description={
                  "Classification based on the product's performance in environmental and circular aspects."
                }
                score={product.dpp.product_info.sac}
              />
            </div>
            <div className="sac-ses-new">
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_ses_v2.svg"}
                title={"Economical and Social"}
                description={
                  "Classification based on the economical and social performance of the value chain."
                }
                score={product.dpp.product_info.ses}
              />
            </div>
          </div>
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 1 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="div-score-sac-ses-v2">
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_AF.svg"}
                title={"Overall Index"}
                description={
                  "The product's overall index considering the 4 dimensions of sustainability (environmental, circular, economical and social). A higher rating represents a more sustainable product."
                }
                score={product.dpp.product_info.score}
              />
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_sac_v2.svg"}
                title={"Environmental and Circularity"}
                description={
                  "Classification based on the product's performance in environmental and circular aspects."
                }
                score={product.dpp.product_info.sac}
              />
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_ses_v2.svg"}
                title={"Economical and Social"}
                description={
                  "Classification based on the economical and social performance of the value chain."
                }
                score={product.dpp.product_info.ses}
              />
              {/* <div className="sac-ses-new-desktop">
                <ScoreInfo
                  currentPage={1}
                  icon={"./images/icon_sac_v2.svg"}
                  title={"Environmental and Circularity"}
                  description={
                    "Classification based on the product's performance in environmental and circular aspects."
                  }
                  score={product.dpp.product_info.sac}
                />
              </div>
              <div className="sac-ses-new-desktop">
                <ScoreInfo
                  currentPage={1}
                  icon={"./images/icon_ses_v2.svg"}
                  title={"Economical and Social"}
                  description={
                    "Classification based on the economical and social performance of the value chain."
                  }
                  score={product.dpp.product_info.ses}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 2 && isMobile) {
    pageContent = (
      <div className="page3-container">
        <div className="div-journey-v2">
          <IndicatorInfo data={dataDetailedIndex} />
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 2 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="div-journey-v2">
              <IndicatorInfo data={dataDetailedIndex} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 3 && isMobile) {
    pageContent = (
      <div className="page4-container">
        <div className="product-maindiv-page4-v2">
          <GeoMap
            journeyTotal_distance={
              product.dpp.product_info.journey.total_distance
            }
            journeyTotal_countries={
              product.dpp.product_info.journey.total_countries
            }
            journeyActivities={
              product.dpp.product_info.journey.production_activities
            }
            isExpanded={isMapExpanded}
            onToggleMap={handleToggleMap}
            touchAreaRef={touchAreaRef}
          />
          {!isMapExpanded && (
            <div className="product-div-journey">
              <Journey
                steps={product.dpp.product_info.journey.production_activities}
              />
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 3 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="product-maindiv-page4-v2 desktop">
              <GeoMap
                journeyTotal_distance={
                  product.dpp.product_info.journey.total_distance
                }
                journeyTotal_countries={
                  product.dpp.product_info.journey.total_countries
                }
                journeyActivities={
                  product.dpp.product_info.journey.production_activities
                }
                isExpanded={isMapExpanded}
                onToggleMap={handleToggleMap}
                touchAreaRef={touchAreaRef}
              />
              {!isMapExpanded && (
                <div className="product-div-journey">
                  <Journey
                    steps={
                      product.dpp.product_info.journey.production_activities
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 4 && isMobile) {
    pageContent = (
      <div className="page5-container">
        {/* <div className="product-maindiv-page5-v2-carousel">
          <AditionalInfo
            data={data}
            currentVisibleSlide={currentVisibleSlide}
            setCurrentVisibleSlide={setCurrentVisibleSlide}
            refNextBack={carouselRef}
          />
        </div> */}
        <ZaraTabs
          refNextBack={carouselRef}
          data={data}
          setCurrentVisibleSlide={setCurrentVisibleSlide}
        />
        <div className="product-maindiv-page5-v2" {...handlers}>
          {currentVisibleSlide === 0 && (
            <div className="properties" ref={scrollAreaRef}>
              {<Properties categories={categoriesData} />}
            </div>
          )}
          {currentVisibleSlide === 1 && (
            <div className="care-instructions-products" ref={scrollAreaRef}>
              {
                <CareInstructions
                  care_instructions={product.dpp.product_info.care_instructions}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 2 && (
            <div className="maintenance-discard" ref={scrollAreaRef}>
              {
                <MaintenanceDiscard
                  longtext_text={
                    product.dpp.product_info.maintenance_repair_instructions
                  }
                  longtext_title={longtext_title}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 3 && (
            <div className="bill-of-materials" ref={scrollAreaRef}>
              {
                <BillOfMaterials
                  data={product.dpp.product_info.bill_of_materials}
                ></BillOfMaterials>
              }
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
        {/* <div
          className="SimilarProducts"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
          ref={touchAreaRef}
        >
          <SimilarProducts
            productList={product.dpp.product_info.similar_products}
            handleMainpage={handleMainpage}
            isOpenDesktop={isSimilarProductsOpen}
            setIsOpenDesktop={setSimilarProductsOpen}
          />
        </div> */}
      </div>
    );
  }
  return isMobile ? (
    <div className="product-fixed-page">
      {showOverlay && (
        <div
          className="overlay-product-main-page"
          onTouchStart={isOverlayClickable ? handleDismissOverlay : null}
          onClick={isOverlayClickable ? handleDismissOverlay : null}
        >
          <div className="overlay-product-main-page-content">
            <img
              alt="icon-overlay-product"
              className="icon-overlay-product"
              src="./images/gif_swipe.gif"
            />
            <div className="overlay-product-text1">Swipe up</div>
            <div className="overlay-product-text2">
              to explore our
              <img
                alt="icon-logo-dpp-overlay"
                className="icon-logo-dpp-overlay"
                src={DppLogoDesktopProduct}
              />
            </div>
            <div
              className="overlay-product-button"
              onClick={handleDismissOverlay}
            >
              Got it!
            </div>
          </div>
        </div>
      )}
      <div className="product-container">
        <div className="page-indicator">
          {Array.from({ length: maxPages }, (_, index) => (
            <div
              key={index}
              className={`indicator-dot ${
                index === currentPage ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
        {currentPage !== 0 && (
          <>
            <div className="header-content-v2">
              <img
                src="./images/icon_arrow_back.svg"
                alt="product-arrow-goback"
                className="product-arrow-goback"
                onClick={handleBackClick}
              />
              <div className="header-content-v2-content center">
                <div className="header-content-v2-title center">
                  {pageTitle}
                </div>
              </div>
              {/* <img
                src="./images/icon_interrogation.svg"
                alt="product-interrogation"
                className="product-interrogation"
              /> */}
            </div>
            <div className="header-v2">
              <ProductHeaderV2
                handleNavigation={returnToMainPage}
                product_info={product.dpp.product_info}
                score={product.dpp.product_info.score}
              />
              <ScoreVertical score={product.dpp.product_info.score} />
            </div>
          </>
        )}
        <div
          className="swipe-main-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
        >
          {pageContent}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`main-container-desktop ${currentPage === 0 ? "footer" : ""}`}
    >
      <div
        className={`gallery-container-desktop ${
          currentPage === 0 ? "footer" : ""
        }`}
      >
        <div className="header-container-v2-desktop">
          <img
            src="./images/icon_arrow_back.svg"
            alt="product-arrow-goback"
            className="product-arrow-goback-desktop"
            onClick={handleBackClick}
          />
          <div className="header-content-v2-content-desktop">
            <div className="header-content-v2-title-desktop">
              {product.dpp.product_info.name}
            </div>
            <div className="header-content-v2-text-desktop">
              {product.dpp.product_info.brand}
            </div>
          </div>
          <div className="brand-container-v2-desktop">
            <div className="made-in-content-v2-text-desktop">
              {
                <div className="text-inline-desktop">
                  Made In&nbsp;
                  <span style={{ display: "inline", marginLeft: "1px" }}>
                    {getCountryDescription(product.dpp.product_info.made_in)}
                  </span>{" "}
                </div>
              }
            </div>{" "}
            {/* <img
              alt="product-interrogation"
              className="product-interrogation"
              src="./images/icon_interrogation.svg"
            /> */}
          </div>
        </div>
        <ProductGallery
          recycled={product.dpp.product_info.composition.recycled}
          compositionList={product.dpp.product_info.composition.fibers}
          certifications={product.dpp.product_info.certifications}
          productImgs={product.dpp.product_info.img}
          touchAreaRef={touchAreaRef}
        />
      </div>
      <div
        className={`content-container-desktop ${
          currentPage === 0 ? "footer" : ""
        }`}
      >
        {currentPage !== 0 && (
          <div className="content-header-desktop">
            <img
              alt="icon-arrow-back-desktop-pages"
              className="icon-arrow-back-desktop-pages"
              src="./images/icon_arrow_back.svg"
              onClick={handleMainpage}
            />
            <div className="header-desktop-content-text">{pageTitle}</div>
          </div>
        )}
        {pageContent}
      </div>
      {currentPage !== 0 && (
        <div
          className={`scroll-bar-pages ${currentPage === 0 ? "footer" : ""}`}
        >
          {[1, 2, 3].map((pageNumber, index) => (
            <div
              key={index}
              className={`scroll-bar-pages-item ${
                pageNumber === currentPage ? "active" : ""
              }`}
            />
          ))}
          <div className="arrow-under-scrollbar-container">
            <img
              alt="arrow-under-scrollbar-up"
              className={`arrow-under-scrollbar ${
                currentPage === 1 ? "disabled" : ""
              }`}
              src="./images/arrow-under-scrollbar.svg"
              onClick={currentPage === 1 ? null : () => handleArrowClick("up")}
            />
            <img
              alt="arrow-under-scrollbar-down"
              className={`arrow-under-scrollbar down ${
                currentPage === 3 ? "disabled" : ""
              }`}
              src="./images/arrow-under-scrollbar.svg"
              onClick={() => handleArrowClick("down")}
            />
          </div>
        </div>
      )}
      {/* {currentPage === 3 && (
        <SimilarProducts
          productList={product.dpp.product_info.similar_products}
          handleMainpage={handleMainpage}
          isOpenDesktop={isSimilarProductsOpen}
          setIsOpenDesktop={setSimilarProductsOpen}
        />
      )} */}

      {currentPage === 0 && (
        <div className="navbar-footer-product">
          <FooterDesktop />
        </div>
      )}
    </div>
  );
};
export default Product;
