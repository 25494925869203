import React from 'react'
import CareInstruction from "../../components/v2/CareInstruction";
import care_instructions_info from '../../pages/v2/jsons/care_instructions.json';

const CareInstructions = ({care_instructions}) => {

  const getCareInstruction = (code) => {
    const care = care_instructions_info.find((care) => care.code === code);
    return care ? care : "Unknown Instruction";
  };

  return (
    <div>
      <div style={{ width: "100%" }} className="div-careinstructions">
        <div className="div-careinstructions-content">
          {care_instructions.map((careinstruction, index) => (
            <CareInstruction
              key={index}
              careinstruction={getCareInstruction(careinstruction)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CareInstructions