import React, { useState, useRef } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { Fab } from "@mui/material";
import "./CertificationsDesktop.css";
import Certification from "../v2/Certification";
import arrow_left from "../../img/arrow_left_new.svg";

//comentar depois
import certification_example from "../../img/global-organic-certification.png";

const CertificationsDesktop = (/* { certifications } */) => {
  const carouselRef = useRef();
  const [selectedCertification, setSelectedCertification] = useState(null);

  const handleImageClick = (certification) => {
    setSelectedCertification(certification);
  };

  const handleClose = () => {
    setSelectedCertification(null);
  };

  /* const certifications = [
    {
      img: certification_example,
      title: "Title",
      description: "This is an example.",
    },
    {
      img: certification_example,
      title: "Title",
      description: "This is an example.",
    },
    {
      img: certification_example,
      title: "Title",
      description: "This is an example.",
    },
    {
      img: certification_example,
      title: "Title",
      description: "This is an example.",
    },
  ]; */

  const certifications = null;

  return (
    <div className="certifications-desktop-container">
      <div className="certifications-desktop-title">Certifications</div>
      <div
        className={`certifications-desktop-carousel-container ${
          selectedCertification !== null ? "selected" : ""
        }`}
      >
        {certifications === null ? (
          <div className="no-certifications-message">
            Certifications were not provided or don’t exist for this product.
          </div>
        ) : selectedCertification ? (
          <div className="certification-detail">
            <img
              src="./images/close-icon.svg"
              className="certification-close-button"
              alt="certification-close-button"
              onClick={handleClose}
            />
            <Certification
              img={selectedCertification.img}
              title={selectedCertification.title}
              description={selectedCertification.description}
            />
          </div>
        ) : (
          <div className="certifications-desktop-carousel">
            <Fab
              className="material-button-left"
              size="small"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
              onClick={() => carouselRef.current?.goBack()}
            >
              <div className="arrow-left-certifications">
                <img
                  className="arrow-left-certifications-img"
                  src={arrow_left}
                  alt="left arrow"
                />
              </div>
            </Fab>
            <ResponsiveContainer
              carouselRef={carouselRef}
              render={(parentWidth, carouselRef) => (
                <StackedCarousel
                  disableSwipe
                  useGrabCursor={false}
                  ref={carouselRef}
                  data={certifications}
                  carouselWidth={parentWidth}
                  slideWidth={60}
                  slideComponent={(props) => (
                    <Slide {...props} onClick={handleImageClick} />
                  )}
                  maxVisibleSlide={3}
                />
              )}
            />
            <Fab
              className="material-button-right"
              size="small"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
              onClick={() => carouselRef.current?.goNext()}
            >
              <div className="arrow-right-certifications">
                <img
                  className="arrow-right-certifications-img"
                  src={arrow_left}
                  alt="left arrow"
                />
              </div>
            </Fab>
          </div>
        )}
      </div>
    </div>
  );
};

// Slide component to be used inside StackedCarousel
const Slide = React.memo(function (props) {
  const { data, dataIndex, onClick } = props;
  const certification = data[dataIndex];
  return (
    <div
      className="certification-carousel-item"
      onClick={() => onClick(certification)}
    >
      <img
        src={certification.img}
        alt="certification"
        className="certification-img"
        draggable={false}
      />
    </div>
  );
});

export default CertificationsDesktop;
