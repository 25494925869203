import React from 'react';
import './CompositionBarChart.css';
import compositionsTrans from '../../pages/v2/jsons/compositions.json';

const colors = ['#0099B0', '#007B8A', '#004D72', '#003366'];

const CompositionBarChart = ({ compositions }) => {
  const sortedCompositions = compositions.sort(
    (a, b) => b.percentage - a.percentage
  );

  const getCompositionDescription = (code) => {
    const composition = compositionsTrans.find(
      (composition) => composition.code === code
    );
    return composition ? composition.description.en : "Unknown Composition";
  };

  return (
    <div className="bar-chart-container">
      {sortedCompositions.map((composition, index) => (
        <div key={index} className="bar-container">
          <div
            style={{
              color: colors[index],
            }}
            className="bar-chart-composition-label"
          >
            {getCompositionDescription(composition.code)}
            <div className="bar-chart-composition-value">
              {composition.value}%
            </div>
          </div>
          <div className="bar-wrapper">
            <div
              className="bar"
              style={{
                width: `${composition.value}%`,
                backgroundColor: colors[index],
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompositionBarChart;
