import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavbarDesktop.css";
import searchicondesktop from "../../img/search_desktop.svg";
import SearchSelected from "../../img/SearchSelected.svg";
import dpp from "../../img/dpp_logo_desktop.svg";
import LanguageSelection from "./LanguageSelection";

const NavbarDesktop = ({ isHomepage }) => {
  // Obtém a rota atual
  const currentLocation = useLocation();

  // Verifica a rota atual
  const isProductsRoute = currentLocation.pathname === "/view";
  const isAboutRoute = currentLocation.pathname === "/about";
  const isSearchRoute = currentLocation.pathname === "/search";
  const languages = ["EN", "PT", "DE", "ES", "FR"];
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredAbout, setIsHoveredAbout] = useState(false);

  return (
    <div className={`navbar-desktop ${isHomepage ? "ishomepage" : ""}`}>
      <div className={`navbar-left-desktop ${isHomepage ? "ishomepage" : ""}`}>
        <NavLink to="/" className="navbar-item-desktop">
          <span
            className={`text-navbardesktop ${isHomepage ? "ishomepage" : ""}`}
            onMouseEnter={() => setIsHoveredAbout(true)}
            onMouseLeave={() => setIsHoveredAbout(false)}
          >
            About Us
          </span>
          {(isAboutRoute || isHoveredAbout) && (
            <div
              className={`text-underline-navbardesktop ${
                isHomepage ? "ishomepage" : ""
              }`}
            >
              {" "}
            </div>
          )}
        </NavLink>
        <div
          className={`separator-navbar-line ${isHomepage ? "ishomepage" : ""}`}
        ></div>
        <NavLink
          to="/view"
          className={`navbar-item-desktop ${isHomepage ? "ishomepage" : ""}`}
        >
          <span
            className={`text-navbardesktop ${isHomepage ? "ishomepage" : ""}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Products{" "}
          </span>
          {(isProductsRoute || isHovered) && (
            <div
              className={`text-underline-navbardesktop ${
                isHomepage ? "ishomepage" : ""
              }`}
            ></div>
          )}
        </NavLink>
      </div>
      <div
        className={`navbar-center-desktop ${isHomepage ? "ishomepage" : ""}`}
      >
        <a
          href="/"
          className={`navbar-item-center-desktop ${
            isHomepage ? "ishomepage" : ""
          }`}
        >
          <img
            src={dpp}
            alt="Ícone"
            className={`main-icon-navbar-desktop ${
              isHomepage ? "ishomepage" : ""
            }`}
          ></img>
        </a>
      </div>
      <div className={`navbar-right-desktop ${isHomepage ? "ishomepage" : ""}`}>
        <NavLink
          to="/search"
          className={`navbar-item-desktop-search ${
            isSearchRoute ? "active" : ""
          }`}
        >
          <div
            className={`icon-search-dot-selected ${
              isSearchRoute ? "active" : ""
            }`}
          >
            <img
              className={`icon-search-desktop ${isSearchRoute ? "active" : ""}`}
              src={searchicondesktop}
              alt="Ícone"
            ></img>
            {/*   <div className="dot-desktop-search"> . </div> */}
          </div>
        </NavLink>
        <LanguageSelection languages={languages}></LanguageSelection>
      </div>
    </div>
  );
};

export default NavbarDesktop;
