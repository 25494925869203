import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Homepage from "../pages/v2/Homepage";
import NotFound from "../pages/v1/NotFound";
import Product from "../pages/v2/Product.jsx";
import Products from "../pages/v2/Products.jsx";
import Search from "../pages/v2/Search.jsx";
import Test from "../pages/v2/Test.jsx";
import jsonData from "../ProductList.json";
import searchList from "../pages/v2/jsons/products_page.json";
import searchListEvent from "../pages/v2/jsons/products_page_event.json";

const App = () => {
  //lista de produtos (a ir buscar a outro ficheiro - ProductList.json)
  const [productList, setProductList] = useState([]);
  const [productSearchList, setProductSearchList] = useState([]);
  const [tokenEvent, setTokenEvent] = useState([false]);
  const [expirationDate, setExpirationDate] = useState(null);

  useEffect(() => {
    setProductList(jsonData);
    setProductSearchList(searchList);
  }, []);

  //QUANDO ENTRA NESTE COMPONENTE
  useEffect(() => {
      checkToken()
  }, [tokenEvent]); 

  //Quando data expira
  useEffect(() => {
    if (expirationDate) {
      const now = new Date().getTime();
      const timeUntilExpiration = expirationDate - now;
   /*    console.log(timeUntilExpiration); */
      if (timeUntilExpiration > 0) {
       /*  console.log(timeUntilExpiration); */
        const timer = setTimeout(() => {
          // Aqui você executa a lógica que deseja quando a data de expiração for alcançada
        /*   console.log("A data de expiração foi alcançada."); */
          checkToken();
        }, timeUntilExpiration);

        return () => clearTimeout(timer); // Limpa o timer ao desmontar
      }
    }
  }, [expirationDate]);

  // Verifica se o item existe
  const checkToken = () => {
    const storedToken = JSON.parse(localStorage.getItem("authToken"));
    if (storedToken && isTokenValid(storedToken)) {
      // Token válido
      setProductSearchList(searchListEvent);
      const expirationDateToken = storedToken.expiration;
      setExpirationDate(expirationDateToken);
      /*  console.log("Token válido. Atualizando lista de produtos."); */
    } else if (storedToken && !isTokenValid(storedToken)) {
      // Token expirado
      setProductSearchList(searchList);
      setTokenEvent(false);
      setExpirationDate("");
      localStorage.removeItem("authToken");

      /* localStorage.removeItem("authToken"); */
      /*   console.log("Token expirado. Mantendo no localStorage."); */
    } else {
      // Token inexistente ou inválido
      setProductSearchList(searchList);
      setTokenEvent(false);
      /* setExpirationDate(""); */
      localStorage.removeItem("authToken");
      /*      localStorage.removeItem("authToken"); */
      /*     console.log(
        "Token inválido ou não encontrado. Removendo do localStorage."
      ); */
    }
  };

  // Função para verificar se o token está dentro do período válido
  const isTokenValid = (token) => {
    if (!token) return false;

    const now = new Date().getTime();
    const { startDate, expiration } = token;

    /*     // O token é válido apenas se estivermos entre a data de início e de expiração
      console.log("Agora:", now);
      console.log("Data de início:", startDate);
      console.log("Data de expiração:", expiration); */
    return now >= startDate && now < expiration;
  };

  const checkTokenEvent = (tokenevent, date) => {
    setTokenEvent(tokenevent);
    setExpirationDate(date);
  };

  const isValidRoute = (route) => {
    // Check if the route is one of the valid routes
    return ["/products", "/view", "/search", "/", "notfound"].includes(route);
  };

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router scrollBehavior="auto">
        <Routes>
          <Route path="/" index element={<Homepage />} />
          <Route
            path="/products"
            element={
              <Product
                productList={productList}
                productListEvent={searchListEvent}
                hasTokenEvent={checkTokenEvent}
              />
            }
          />
          <Route
            path="/view"
            element={<Products productList={productSearchList} hasTokenEvent={checkTokenEvent} />}
          />
          <Route
            path="/search"
            element={<Search productList={productSearchList} />}
          />
          {/*   <Route path="/test" element={<Test />} /> */}
          <Route path="/error" element={<NotFound />} />
          <Route
            path="*"
            element={isValidRoute("*") ? null : <Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default App;
