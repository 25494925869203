import React, { useState, useRef, useImperativeHandle, useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "./Aditionalinfo.css";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { Fab } from "@mui/material";

function Carousel({ data, setCurrentVisibleSlide, refNextBack }) {
  const carouselRef = useRef();
  const ref = React.useRef();
  const [activeIndex,setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleSlideChange = (dataIndex) => {
    setCurrentVisibleSlide(dataIndex);
    setActiveIndex(dataIndex);
  };

  const [index, setIndex] = useState();
  const currentItem = data.find(item => item.id === activeIndex);

  useImperativeHandle(
    refNextBack,
    () => ({
      goToNextSlide() {
        carouselRef.current?.goNext();
      },
      goToPrevSlide() {
        carouselRef.current?.goBack();
      },
    }),
    [carouselRef]
  );

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simule um tempo de carregamento
    setTimeout(() => {
      setLoaded(true);
    }, 1000); // Tempo de espera arbitrário para simular o carregamento
  }, []);

  return (
    <>
      <div
        className={`carousel-container-aditional ${
          loaded ? "" : "carousel-loading"
        }`}
      >
        {isMobile && (
          <Fab
            className="material-button-left"
            size="small"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
            onClick={() => carouselRef.current?.goBack()}
          >
            <KeyboardArrowLeft style={{ fontSize: 20 }} />
          </Fab>
        )}
        <ResponsiveContainer
          carouselRef={carouselRef}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 3;

            return isMobile ? (
              <>
                <StackedCarousel
                  ref={carouselRef}
                  data={data}
                  carouselWidth={parentWidth}
                  slideWidth={60}
                  slideComponent={Slide}
                  maxVisibleSlide={3}
                  currentVisibleSlide={currentVisibleSlide}
                  disableSwipe
                  onActiveSlideChange={(newCenterDataIndex) =>
                    handleSlideChange(newCenterDataIndex)
                  }
                  transitionTime={100}
                  swipeSpeed={0.3}
                />
                <div className="slideproducts-carousel-texts">
                  <div className="slideproduct-carousel-name">
                    {currentItem.text}
                  </div>
                </div>
              </>
            ) : (
              <>
                <StackedCarousel
                  ref={carouselRef}
                  data={data}
                  carouselWidth={parentWidth}
                  slideWidth={60}
                  slideComponent={(props) => (
                    <Slide
                      {...props}
                      activeIndex={activeIndex}
                      totalSlides={data.length}
                      carouselRef={carouselRef}
                    />
                  )}
                  maxVisibleSlide={currentVisibleSlide}
                  currentVisibleSlide={currentVisibleSlide}
                  disableSwipe
                  useGrabCursor={false}
                  onActiveSlideChange={handleSlideChange}
                  transitionTime={100}
                  swipeSpeed={0.3}
                />

                <div className="slideproducts-carousel-texts">
                  <div className="slideproduct-carousel-name">
                    {currentItem.text}
                  </div>
                </div>
              </>
            );
          }}
        />
        {isMobile && (
          <Fab
            className="material-button-right"
            size="small"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
            onClick={() => carouselRef.current?.goNext()}
          >
            <KeyboardArrowRight style={{ fontSize: 20 }} />
          </Fab>
        )}
      </div>
    </>
  );
}

export default Carousel;

const Slide = React.memo(function (props) {
  const { data, dataIndex, isCenterSlide, activeIndex, carouselRef, totalSlides } = props;
  const { image, text } = data[dataIndex];

  const handleClick = () => {
    // Calculate next and previous index with wrap-around behavior
    const prevIndex = (activeIndex - 1 + totalSlides) % totalSlides;
    const nextIndex = (activeIndex + 1) % totalSlides;

    if (dataIndex === prevIndex) {
      carouselRef.current?.goBack(); // Go to previous slide
    } else if (dataIndex === nextIndex) {
      carouselRef.current?.goNext(); // Go to next slide
    }
  };

  return (
    <div
      className="slideproducts-carousel-maindiv"
      onClick={handleClick}
      style={{ cursor: (dataIndex === (activeIndex - 1 + totalSlides) % totalSlides || dataIndex === (activeIndex + 1) % totalSlides) ? "pointer" : "default" }}
    >
      <div className="slideproduct-carousel-image">
        {isCenterSlide ? (
          <div className="slideproperty-image">
            <img alt="nome" draggable={false} src={image} />
          </div>
        ) : (
          <div className="slideproperty-image-opacity">
            <img
              className="slide-image"
              alt="nome"
              draggable={false}
              src={image}
            />
          </div>
        )}
      </div>
    </div>
  );
});


