import React, { useEffect, useRef, memo,useState } from 'react';
import './CompositionGraph.css';
import Chart from 'chart.js/auto';
//imports dos jsons para mapear tradução
import compositions from '../../pages/v2/jsons/compositions.json';

const CompositionGraph = ({ recycled }) => {

  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chartRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const canvas = chartRef.current;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
    
      if (screenWidth <= 350 && screenHeight <= 650) {
        canvas.height = 75;
      } else if (screenWidth <= 350 && screenHeight <= 680) {
        canvas.height = 80;
      } else if (screenWidth <= 600 && screenHeight <= 740) {
        canvas.height = 90;
      } else if (screenWidth <= 600) {
        canvas.height = 110;
      } else if (screenWidth <= 1024) {
        canvas.height = 110;
      } else {
        canvas.height = 130;
      }
    };
    

    // Set initial height based on screen width
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getFontSize = (baseSize) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth <= 350 && screenHeight <= 650) {
      return `${baseSize * 0.62}px`;
    } else if (screenWidth <= 350 && screenHeight <= 680) {
      return `${baseSize * 0.65}px`;
    } else if (screenWidth <= 600 && screenHeight <= 740) {
      return `${baseSize * 0.75}px`;
    } else if (screenWidth <= 600) {
      return `${baseSize * 0.95}px`;
    } else if (screenWidth <= 1024) {
      return `${baseSize * 0.95}px`;
    } else {
      return `${baseSize * 1.05}px`;
    }
  };

  const getMiddleValueSpace = (baseSize) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth <= 350 && screenHeight <= 650) {
      return baseSize * 0.6;
    } else if (screenWidth <= 350 && screenHeight <= 680) {
      return baseSize * 0.7;
    } else if (screenWidth <= 600 && screenHeight <= 740) {
      return baseSize * 0.75;
    } else if (screenWidth <= 600) {
      return baseSize * 0.85;
    } else if (screenWidth <= 1024) {
      return baseSize * 0.9;
    } else {
      return baseSize * 0.95;
    }
  };

  useEffect(() => {

    const remainingPercentage = 100 - recycled;

    const data = {
      datasets: [
        {
          data: [recycled, remainingPercentage],
          backgroundColor: ["#37ADD4", "#66666615"],
          borderWidth: [1, 0],
          borderColor: ["#37ADD4", "transparent"],
          borderRadius: 50,
          hoverOffset: 0,
        },
      ],
    };

    // Labels and percentage display for each slice
    const doughnutLabelsLine = {
      id: 'doughnutLabelsLine',
      afterDraw(chart) {
        const { ctx } = chart;

        // Draw the recycled value in the center
        ctx.save();
        ctx.font = `bold ${getFontSize(24)} Roboto`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#37ADD4';
        ctx.fillText(`${recycled}%`, chart.chartArea.width / 2, chart.chartArea.height / 2 + 5);
        ctx.restore();

        ctx.save();
        ctx.font = `${getFontSize(14)} Roboto`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#37ADD4';
        ctx.fillText(
          `Recycled`,
          chart.chartArea.width / 2,
          chart.chartArea.height / 2 + getMiddleValueSpace(28)
        );
        ctx.restore();
      },
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 10,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: "index",
        },
        cutout: "95%",
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
      },
      plugins: [doughnutLabelsLine],
    };

    const myChart = new Chart(chartRef.current, config);

    return () => {
      myChart.destroy();
    };
  }, [recycled]);

  return (
    <div className='composition-container'>
      <div className='composition-line'>
        <div className='container-of-donut-container'>
          <canvas ref={chartRef} width='130' height='130'></canvas>
        </div>
      </div>
    </div>
  );
};

export default memo(CompositionGraph);
